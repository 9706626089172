import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Socials from '../components/Socials'
import Campaign from "../components/CampaignForm"

const Vip = () => {

  return(
    <Layout>
      <SEO title="Mov3 Fitness App" description="Brand new fitness app by Steve Cook and the Fitness Culture team." />
      <div className="py-20 pb-2 paper px-5 min-h-screen">
        <div className="container mx-auto my-10">
          <div className="flex flex-wrap items-center justify-center">
            <div className="w-full md:w-1/2 justify-center">
            <h1 className="text-3xl md:text-4xl leading-none pb-10 text-center">Steve Cook VIP</h1>
              <p className="font-light mt-10">Join my email list and get exclusive access to free training and workout advice, get deals and discounts for all
              the brands I work with, and be the first to know when big things are about to happen.</p>
              <p className="font-light mt-2">Thank you for support and following along on my journey and I'm excited to take part in yours.</p>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center">
            <div className="w-full md:w-1/2 flex justify-center">
              <Campaign campaign="492448630" cta="Become a VIP" />
            </div>
          </div>
          <div className="mb-10">
            <Socials />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Vip